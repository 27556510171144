import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import PostContent from "../components/content/postContent"
import NewsletterForm from "../components/form/newsletterForm"
import Copyright from "../components/util/copyright"
import "../assets/scss/layout/pages/home.scss"

const IndexPage = ({data, path}) => {
  const content = data.wpPage

  return (
    <Layout isHome={true} path={path}>
      <SEO title={'Home'} />
      <div className={'banner'}>
        <Img
          fluid={content.featuredImage.node.localFile.childImageSharp.fluid}
          alt={content.featuredImage.node.title}
        />
        <Copyright text={'Cristian Escobar'} />
      </div>

      <div className={'newsletter'}>
        <p className={'title'}>Stay in touch !</p>
        <NewsletterForm />
        <p className={'text'}>Join our mailing list. You can unsubscribe at any time.</p>
      </div>

      <PostContent className={'home__content'} content={content.content} />
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(slug: {eq: "home"}) {
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(sizes: "800") {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  }
`

export default IndexPage
