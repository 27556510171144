import React from "react"

const NewsletterForm = () => (
  <form
    name={'newsletter'}
    className={'newsletter__form form'}
    data-netlify={'true'}
    data-netlify-honeypot={'bot-field'}
    method={'POST'}
  >
    <input type={'hidden'} name={'form-name'} value={'newsletter'} />
    <input name={"email"} type={'email'} placeholder={'E-mail'} />
    <button type={'submit'}>Subscribe</button>
  </form>
)

export default NewsletterForm
